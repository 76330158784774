$mobile-landscape-max: 812px;
$m: 768px;
$l: 991px;
$xl: 1199px;
$xxl: 1440px;

@mixin tablet {
    @media (min-width: #{$m}), screen and (min-width: $mobile-landscape-max) and (orientation: landscape) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$l}) {
        @content;
    }
}

@mixin large-desktop {
    @media (min-width: #{$xl}) {
        @content;
    }
}

@mixin hd-desktop {
    @media (min-width: #{$xxl}) {
        @content;
    }
}
