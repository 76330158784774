@use 'colors.scss';

.form-group {
  position: relative;
  margin: 1.5rem 0 2rem;

  input,
  textarea {
    font-size: 1rem;
    padding: 1.1rem;
    display: block;
    width: 100%;
    border: none;
    border: 1px solid map-get(colors.$colors, 5);
  }

  .ng-invalid.ng-touched {
    border-color: map-get(colors.$colors, 1);
  }

  .error-msg {
    color: map-get(colors.$colors, 1);
    position: absolute;
    font-size: 0.8rem;
    bottom: -1rem;
    left: 0;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  label {
    color: map-get(colors.$colors, 6);
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 1.1rem;
    top: 1.3rem;
    transition: 0.2s ease all;
  }

  input[required] + label:after,
  textarea[required] + label:after {
    content: "*";
    position: relative;
    top: -2px;
    left: 2px;
  }

  input:focus ~ label,
  textarea:focus ~ label,
  .not-empty ~ label {
    top: -0.6rem;
    padding: 0.25rem;
    background: map-get(colors.$colors, 2);
    font-size: 0.75rem;
  }

  textarea {
    min-height: 11rem;
    resize: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px map-get(colors.$colors, w) inset !important;
  }
}
