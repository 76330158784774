$colors: (
  b: #212121,
  w: #fff,
  1: #e12954,
  2: #f7f4f1,
  3: #eff3f3,
  4: #f9f7f3,
  5: #d4d4d4,
  6: #949494
);

@each $colorName, $value in $colors {
  .col-#{$colorName} {
    color: $value;
  }

  .bg-col-#{$colorName} {
    background-color: $value;
  }
}
