$fonts: (
  primary: (
    "Plantin",
    sans-serif,
  ),
  secondary: (
    "Lato",
    Helvetica,
    Arial,
    sans-serif,
  ),
  script: (
    "Nanum Pen Script",
    cursive,
  ),
);

@each $font, $value in $fonts {
  .font-#{$font} {
    font-family: $value;
  }
}

body {
  font-family: map-get($fonts, secondary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: map-get($fonts, primary);
  font-weight: 300;
}
