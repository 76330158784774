@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/lato/lato-v17-latin-ext_latin-regular.woff2") format("woff2"),
    url("/assets/fonts/lato/lato-v17-latin-ext_latin-regular.woff") format("woff");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/lato/lato/lato-v17-latin-ext_latin-700.woff2") format("woff2"),
    url("/assets/fonts/lato/lato-v17-latin-ext_latin-700.woff") format("woff");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  src:  url("/assets/fonts/lato/Lato-Semibold.woff") format("woff");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Nanum Pen Script";
  src: url("/assets/fonts/nanum/nanum-pen-script-v15-latin-regular.woff2")
  format("woff2"),
  url("/assets/fonts/nanum/nanum-pen-script-v15-latin-regular.woff") format("woff");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Plantin";
  src:  url("/assets/fonts/plantin/Plantin-Light.woff") format("woff");
  font-style: normal;
  font-display: auto;
  font-weight: 300;
}

@font-face {
  font-family: "Plantin";
  src:  url("/assets/fonts/plantin/Plantin-LightItalic.woff") format("woff");
  font-style: italic;
  font-display: auto;
  font-weight: 300;
}
