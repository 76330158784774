////
/// Normalize
/// @group helpers
////

/// A collection of HTML element and attribute style-normalizations.
*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
progress,
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    vertical-align: baseline;
    color: inherit;
    text-transform: none;
    outline: none;
    border-radius: 0;
}

// Add the correct display in IE 9-.
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

// Remove the margin in all browsers (opinionated).
body {
    line-height: 1;
    margin: 0;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;

    &::before,
    &::after {
        content: '';
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

// 1. Prevent adjustments of font size after orientation changes in
//    IE on Windows Phone and in iOS.
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

// Add the correct display in IE 9-.
audio,
canvas,
progress,
video {
    display: inline-block;
}

// Add the correct display in iOS 4-7.
audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

// 1. Remove the gray background on active links in IE 10.
// 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
a {
    background-color: transparent;

    // 1
    text-decoration: none;
    -webkit-text-decoration-skip: objects;

    // 2
    cursor: pointer;
    outline: none;

    &:active,
    &:hover {
        outline: 0;
    }
}

// 1. Remove the bottom border in Chrome 57- and Firefox 39-.
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
abbr[title] {
    // 1
    border-bottom: none;

    // 2
    text-decoration: underline;
    text-decoration: underline dotted;
}

// 1. Prevent the duplicate application of `bolder` by the next rule in Safari 6.
// 2. Add the correct font weight in Chrome, Edge, and Safari.
b,
strong {
    // 1
    font-weight: inherit;

    // 2
    font-weight: bold;
}

// Add the correct font style in Android 4.3-.
dfn {
    font-style: italic;
}

// Add the correct font style in Android 4.3-.
address {
    font-style: normal;
}

// Hide the overflow in IE.
svg:not(:root) {
    overflow: hidden;
}

// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.
hr {
    // 1
    box-sizing: content-box;
    height: 0;

    // 2
    overflow: visible;
}

// Contain overflow in all browsers
pre {
    overflow: auto;
}

// Address odd `em`-unit font size rendering in all browsers
// Correct font family set oddly in IE 6, Safari 4/5, and Chrome
code,
kbd,
pre,
samp {
    font-family: monospace;
    font-size: 1em;
}

// Show the overflow in IE.
// 1. Show the overflow in Edge.
button,
input {
    overflow: visible;
}

// Remove the inheritance of text transform in Edge, Firefox, and IE.
// 1. Remove the inheritance of text transform in Firefox.
button,
select {
    // 1
    text-transform: none;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    -webkit-appearance: button;

    // 2
    cursor: pointer;
    background-color: transparent;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

// Remove the inner border and padding in Firefox.
button::-moz-focus-inner,
input::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

input,
select,
textarea {
    line-height: normal;
    outline: none;
    background-color: transparent;
    width: 100%;
    background-image: none;
    background-clip: padding-box;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

// Correct the cursor style of increment and decrement buttons in Chrome.
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    height: auto;
}

// 1. Correct the odd appearance in Chrome and Safari.
// 2. Correct the outline style in Safari.
input[type='search'] {
    // 1
    -webkit-appearance: textfield;

    // 2
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    outline-offset: -2px;
}

// Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
::-webkit-file-upload-button {
    // 1
    -webkit-appearance: button;

    // 2
    font: inherit;
}

// Remove the default vertical scrollbar in IE.
textarea {
    overflow: auto;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

// IE makes empty image ~30px, FF ~16, and chrome 0
img[src*=''] {
    width: 0;
    height: 0;
}

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
