@use 'colors';
@use 'breakpoints';

body {
  color: map-get(colors.$colors, b);
}

a {
  transition: color 0.2s ease-in;
  &:hover {
    color: map-get(colors.$colors, 1);
  }
}

.container {
  min-height: 100vh;

  .inner {
    padding-top: 4.7rem;
    @include breakpoints.tablet {
      padding-top: 7rem;
    }

    @include breakpoints.desktop {
      padding-top: 12.75rem;
    }

    @include breakpoints.hd-desktop {
      padding-top: 16rem;
    }
  }
}

.wrap {
  max-width: 1274px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;

  @include breakpoints.tablet {
    padding-left: 69px;
    padding-right: 69px;
  }

  @include breakpoints.desktop {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include breakpoints.hd-desktop {
    max-width: 1456px;
  }
}

@mixin cta-active {
  color: map-get(colors.$colors, w);
  &:before {
    transform: initial;
  }
}

.cta {
  position: relative;
  color: map-get(colors.$colors, 1);
  border: 1px solid map-get(colors.$colors, 1);
  font-size: 1rem;
  line-height: 1, 5;
  letter-spacing: 0.5px;
  padding: 14px 15px 16px;
  border-radius: 28px;
  min-width: 132px;
  overflow: hidden;
  transition: 0.2s color;
  z-index: 10;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 100%;
    background-color: map-get(colors.$colors, 1);
    border-radius: 28px;
    transition: 0.5s transform cubic-bezier(0.47, 1.64, 0.48, 0.68);
    transform: translateX(100%);
    z-index: -1;
  }

  &:hover {
    @include cta-active;
  }

  &.active {
    @include cta-active;
  }

  &.busy {
    pointer-events: none;
    transition: 0.4s;
    background-color: map-get(colors.$colors, b);
    border-color: map-get(colors.$colors, b);
    color: map-get(colors.$colors, w);
    // @include cta-active;
    span {
      display: block;
      position: relative;
      animation: bounce infinite 1.5s;
      @keyframes bounce {
        0%,
        100% {
          transform: scale(0.95);
        }
        50% {
          transform: scale(1);
        }
      }
    }

    &:before {
      display: none;
    }
  }

  &[disabled] {
    pointer-events: none;
    background: map-get(colors.$colors, 5);
    border-color: map-get(colors.$colors, 5);
    color: map-get(colors.$colors, w);
  }
}
